import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import * as s from "./styles/globalStyles";
import styled from "styled-components";
import { isMobile } from "react-device-detect";
import CircleTextLogo from "./CircleTextLogo";
import * as md5 from "md5";
import { redirect } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  solid,
  regular,
  brands,
  icon,
} from "@fortawesome/fontawesome-svg-core/import.macro";

const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;

export const StyledButton = styled.button`
  color: #fff;
  font-size: 2rem;
  border-radius: 50px;
  padding: 0.6rem 3rem;
  position: relative;
  overflow: hidden;
  background-color: #ff0000;
  background: linear-gradient(to right, #e66465, #9198e5, #4ed, yellow);
  background-size: 700% 700%;
  animation: gradation 1.7s ease-in infinite;
  :after {
    content: "";
    width: 10px;
    height: 10px;
    border: 2px solid #fff;
    border-top: transparent;
    border-right: transparent;
    transform: rotate(-135deg);
    position: absolute;
    top: 0;
    bottom: 0;
    right: 1.6rem;
    margin: auto;
  }
`;

export const StyledLinkedButton = styled.button`
  color: #fff;
  font-size: 2rem;
  border-radius: 50px;
  padding: 0.6rem 3rem;
  position: relative;
  overflow: hidden;
  background-color: #ff0000;
  background: linear-gradient(to right, #e66465, #9198e5, #4ed, yellow);
  background-size: 700% 700%;
  animation: gradation 1.7s ease-in infinite;
`;

export const StyledButtonFree = styled.button`
  padding: 10px;
  border-radius: 50px;
  border: none;
  background-color: var(--secondary);
  padding: 10px;
  font-weight: bold;
  color: var(--secondary-text);
  width: 100px;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const StyledButtonvipwl = styled.button`
  padding: 10px;
  margin-left: 20px;
  border-radius: 50px;
  border: none;
  background-color: blue;
  font-weight: bold;
  color: var(--secondary-text);
  width: 100px;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const StyledRoundButton = styled.button`
  padding: 10px;
  border-radius: 100%;
  border: none;
  background-color: lightblue;
  padding: 10px;
  font-weight: bold;
  font-size: 15px;
  color: var(--secondary-text);
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
  @media (min-width: 767px) {
    flex-direction: row;
  }
`;

export const StyledLogo = styled.img`
  width: 100%;
  @media (min-width: 767px) {
    width: 500px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const StyledImg = styled.img`
  box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
  /* border: 4px dashed var(--secondary); */
  /* background-color: var(--accent); */
  border-radius: 100%;
  width: 200px;
  @media (min-width: 900px) {
    width: 250px;
  }
  @media (min-width: 1000px) {
    width: 300px;
  }
  transition: width 0.5s;
`;

export const StyledImgThanks = styled.img`
  /* box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
  border: 4px solid var(--secondary); */
  /* background-color: var(--accent);
  border-radius: 100%; */
  width: 200px;
  @media (min-width: 900px) {
    width: 300px;
  }
  @media (min-width: 1000px) {
    width: 500px;
  }
  transition: width 0.5s;
`;

export const StyledLink = styled.a`
  color: var(--secondary);
  text-decoration: none;
`;

function App() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState(
    `Discordとメタマスクを接続しますか？`
  );

  const [mintAmount, setMintAmount] = useState(1);
  const [minted, setminted] = useState(0);
  const [accessToken, setAccessToken] = useState(false);
  const [tokenType, setTokenType] = useState(false);
  const [alignment, setAlignment] = useState(false);

  const [discoInfo, setdiscoInfo] = useState({
    avatar: "",
    id: "",
    username: "",
    discriminator: "",
  });

  const fragment = new URLSearchParams(window.location.hash.slice(1));

  const checkFragment = () => {
    if (fragment) {
      setAccessToken(fragment.get("access_token"));
      setTokenType(fragment.get("token_type"));
    }
  };

  const checkDiscoInfo = async () => {
    if (accessToken === false || accessToken === null) {
      window.location.replace(
        "https://discord.com/api/oauth2/authorize?client_id=1106113825557725286&redirect_uri=https%3A%2F%2Fsdmal.pages.dev%2F&response_type=token&scope=identify"
      );
    }
    const discoResponse = await fetch("https://discord.com/api/users/@me", {
      headers: {
        authorization: `${tokenType} ${accessToken}`,
      },
    });
    const discoRes = await discoResponse.json();
    // console.log(discoRes);
    setdiscoInfo(discoRes);
  };

  const alignmentPost = async () => {
    let gas_id = process.env.REACT_APP_GASTOKEN;
    let token = md5(`${gas_id}${discoInfo.id}${blockchain.account}`);
    let data = { id: discoInfo.id, address: blockchain.account, token: token };
    // console.log(JSON.stringify(data));
    fetch(
      "https://script.google.com/macros/s/AKfycbzvn_5_oTmuVOya71yvBywN1fYsbi2Wi9QAsrsSco3NaogmFdaijjXK0igzxIn-YadP/exec",
      {
        method: "POST",
        "Content-Type": "application/json",
        body: JSON.stringify(data),
      }
    )
      .then((result) => result.json())
      .then((response) => {
        // console.log(response);
        if (response.status == "updated") {
          setFeedback(`連携が更新されました。`);
          setAlignment(true);
        } else if (response.status == "added") {
          setFeedback(`連携が追加されました。`);
          setAlignment(true);
        } else {
          setFeedback(`連携がうまくいかなかったようです`);
          setAlignment(false);
        }
      })
      .catch(console.error);

    // const discoResponse = await fetch(
    //   "https://script.google.com/macros/s/AKfycbxAilKQkumx4M1bw603feIF6uvQvsxtZhf_A7scMREw-9lcc65NodyqfnIGVccBIfPePw/exec",
    //   {
    //     method: "POST",
    //     headers: {
    //       "Content-Type": "application/json",
    //       mode: "no-cors",
    //       // authorization: `${tokenType} ${accessToken}`,
    //     },
    //     body: JSON.stringify(data),
    //   }
    // );
    // const discoRes = await discoResponse.json();
    // console.log(discoRes);
    // setdiscoInfo(discoRes);
  };

  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  });

  const getData = () => {
    if (
      blockchain.account !== "" &&
      blockchain.account !== undefined &&
      blockchain.smartContract !== null
    ) {
      dispatch(fetchData(blockchain.account));
    }
  };

  const getInit = () => {
    if (blockchain.account === undefined) {
      setFeedback(`Click buy to mint your NFT.`);
      setMintAmount(1);
    }
    getData();
    checkFragment();
    // checkDiscoInfo();
  };
  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };
  useEffect(() => {
    getConfig();
    getData();
  }, []);

  useEffect(() => {
    getInit();
  }, [blockchain.account]);

  return (
    <s.Screen>
      <s.Container
        flex={1}
        ai={"center"}
        style={{ padding: 24, backgroundColor: "#232330" }}
        // image={CONFIG.SHOW_BACKGROUND ? "/config/images/bg.jpeg" : null}
        image={isMobile ? "/config/images/bg.png" : "/config/images/bg.png"}
        // image={isMobile ? null : null}
      >
        {/* <StyledLogo
          alt={"logo"}
          src={
            alignment
              ? "/config/images/connected.svg"
              : "/config/images/connect.svg"
          }
        /> */}
        {/* <StyledLogo alt={"logo"} src={null} /> */}
        <ResponsiveWrapper
          flex={1}
          style={{ padding: 24, background: "" }}
          test
        >
          {/* {isMobile ? (<></>) : (<>

            <s.Container flex={1} jc={"center"} ai={"center"}>
            <StyledImg alt={"example"} src={"/config/images/logo.png"} />
          </s.Container>
          </>)} */}
          <s.Container flex={1} jc={"center"} ai={"center"}>
            <CircleTextLogo />
            {/* <StyledImg alt={"example"} src={"/config/images/logo.png"} /> */}
          </s.Container>

          <s.SpacerLarge />
          <s.Container
            flex={2}
            jc={"center"}
            ai={"center"}
            style={{
              backgroundColor: "rgba(0,0,0,0.7)",
              padding: 24,
              borderRadius: 24,
              // border: "4px dashed var(--secondary)",
              boxShadow: "0px 5px 11px 2px rgba(0,0,0,0.7)",
            }}
          >
            {discoInfo.username == null || discoInfo.username == "" ? (
              <>
                <StyledButton
                  onClick={(e) => {
                    e.preventDefault();
                    checkDiscoInfo();
                  }}
                >
                  {accessToken == null || accessToken == false ? (
                    <>
                      <FontAwesomeIcon icon={brands("discord")} />
                      {"認証"}
                    </>
                  ) : (
                    <>
                      <FontAwesomeIcon icon={brands("discord")} />
                      {"ID取得"}
                    </>
                  )}
                </StyledButton>
              </>
            ) : (
              <>
                <s.TextTitle style={{ textAlign: "center", color: "yellow" }}>
                  {discoInfo.username}#{discoInfo.discriminator}
                </s.TextTitle>
              </>
            )}
            <s.TextDescription
              style={{
                textAlign: "center",
                color: "var(--primary-text)",
              }}
            ></s.TextDescription>
            <s.SpacerSmall />
            {blockchain.account === "" ||
            blockchain.smartContract === null ||
            blockchain.account === undefined ? (
              <s.Container ai={"center"} jc={"center"}>
                <s.TextDescription
                  style={{
                    textAlign: "center",
                    color: "var(--accent-text)",
                  }}
                >
                  Connect to the {CONFIG.NETWORK.NAME} network
                </s.TextDescription>
                <s.SpacerSmall />
                <StyledButton
                  onClick={(e) => {
                    e.preventDefault();
                    dispatch(connect());
                    getData();
                  }}
                >
                  <FontAwesomeIcon icon={solid("wallet")} />

                  {"接続"}
                </StyledButton>
                {blockchain.errorMsg !== "" ? (
                  <>
                    <s.SpacerSmall />
                    <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                      }}
                    >
                      {blockchain.errorMsg}
                    </s.TextDescription>
                  </>
                ) : null}
              </s.Container>
            ) : (
              <>
                <s.TextDescription
                  style={{
                    textAlign: "center",
                    color: "var(--accent-text)",
                  }}
                >
                  {"接続アドレス：" + blockchain.account}
                </s.TextDescription>
                <s.TextDescription
                  style={{
                    textAlign: "center",
                    color: "var(--accent-text)",
                  }}
                >
                  {feedback}
                </s.TextDescription>

                {alignment ? (
                  <>
                    <StyledLinkedButton
                      onClick={(e) => {
                        e.preventDefault();
                      }}
                    >
                      <FontAwesomeIcon icon={solid("link")} />
                    </StyledLinkedButton>
                  </>
                ) : (
                  <>
                    {" "}
                    <StyledButton
                      onClick={(e) => {
                        e.preventDefault();
                        alignmentPost();
                      }}
                    >
                      <FontAwesomeIcon icon={brands("discord")} />
                      +
                      <FontAwesomeIcon icon={solid("wallet")} />
                      =
                      <FontAwesomeIcon icon={solid("plug")} />
                    </StyledButton>
                  </>
                )}
              </>
            )}
            <s.SpacerMedium />

            <s.TextDescription
              style={{ textAlign: "center", color: "var(--accent-text)" }}
            >
              NFT Market Place
            </s.TextDescription>
            <s.SpacerSmall />
            <StyledLink target={"_blank"} href={CONFIG.MARKETPLACE_LINK}>
              {CONFIG.MARKETPLACE}
            </StyledLink>
          </s.Container>
          <s.SpacerLarge />

          <s.Container flex={1} jc={"center"} ai={"center"}>
            <CircleTextLogo />
          </s.Container>
        </ResponsiveWrapper>
        <s.SpacerMedium />
        <s.Container jc={"center"} ai={"center"} style={{ width: "70%" }}>
          <s.TextDescription
            style={{
              textAlign: "center",
              color: "var(--primary)",
            }}
          >
            正しいネットワークに接続されていることを確認してください (
            {CONFIG.NETWORK.NAME}{" "}
            Mainnet)と正しいアドレスに接続されていることを確認してください。
          </s.TextDescription>
        </s.Container>
      </s.Container>
    </s.Screen>
  );
}

export default App;
