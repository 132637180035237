import React from "react"; //Reactを読み込んでいる

function Page1() {
  //画面表示の為のrenderメソッドを定義する
  return (
    <div>
      {" "}
      <>
        <div class="flex items-center justify-center h-screen bg-discord-gray text-white">
          <a
            id="login"
            href="https://discord.com/api/oauth2/authorize?client_id=1032859432687784026&redirect_uri=https%3A%2F%2Fdmal.pages.dev%2F&response_type=token&scope=identify"
            class="bg-discord-blue  text-xl px-5 py-3 rounded-md font-bold flex items-center space-x-4 hover:bg-gray-600 transition duration-75"
          >
            <i class="fa-brands fa-discord text-2xl"></i>
            <span>Login with Discord</span>
          </a>
        </div>
      </>
    </div>
  );
}

export default Page1;
